* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #fff;
  background: #333;
  overflow: hidden;
}

.landing-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding-top: 50px;
}

.landing {
  position: relative;
  background-image: url('https://wallpapertag.com/wallpaper/full/0/8/4/681203-nasa-hd-wallpapers-1920x1080-computer.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.landing h1 {
  font-size: 50px;
}

.landing p {
  font-size: 20px;
}

.countdown {
  font-size: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.countdown div {
  padding: 20px;
  border: 1px #fff solid;
  border-radius: 10px;
  background: #000;
  opacity: 0.7;
  margin: 5px;
}

.countdown div:first-child {
  background: #17a2b8;
}

.countdown span {
  display: block;
  font-size: 25px;
}

@media (max-width: 650px) {
  .landing img {
    width: 70%;
  }

  .landing h1 {
    font-size: 40px;
  }

  .countdown {
    font-size: 30px;
    flex-direction: column;
  }

  .countdown div {
    display: none;
  }

  .countdown div:first-child {
    display: block;
    width: 80%;
    padding: 10px;
  }
}


@media (max-height: 600px) {
  img {
    width: 20%;
  }

  p {
    display: none;
  }
}

@media (max-height: 400px) {
  img {
    padding-bottom: 30px;
  }

  h1 {
    display: none;
  }
}